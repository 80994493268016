<template lang="pug">
  .root
    div(class="target-header") ターゲット設定>>
      router-link(:to="{ name: 'SettingTargetNew' }" :class="currentRouteName==='SettingTargetNew' ? 'test-class-a' : 'test-class-b'") 新規作成
      router-link(@click.native="nowTarget()" to="" :class="currentRouteName==='SettingTargetNow' ? 'test-class-a' : 'test-class-b'") 現在のターゲット
      router-link(:to="{ name: 'SettingTargetHistoryIndex' }" :class="currentRouteName.startsWith('SettingTargetHistory') ? 'test-class-a' : 'test-class-b'") 終了済みターゲット

      span(v-if="currentRouteName==='SettingTargetGift'" class="test-class-a") >>お礼をする
    
</template>

<style src="./Header.scss" lang="scss" scoped>
</style>

<script>

export default {
  name: "SettingTargetNew",
  components: {},
  data() {
    return {
    }
  },
  props: {},
  computed: {
    currentRouteName() {
        return this.$route.name;
    }
  },
  watch: {},
  mounted() {},
  async created() {},
  updated() {},
  destroyed() {},
  methods: {
    async nowTarget() {
      await this.$store.dispatch('target/getNowTarget')
      if (this.$store.state.target.now && this.$store.state.target.now.id) {
        this.$router.push({ name:'SettingTargetNow' })
      }else{
        this.$alert('現在のターゲットはありません。');
        return
      }
    }
  }
};
</script>
