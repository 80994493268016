<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="content-left")
        target-components-header

        div(class="now-target-content" v-if="data.id")
          div(class="now-target-content-item")
            div ターゲット名
            div {{ data.name }}
            div 現在の達成率
            div {{ percent }}%
            div 累計ｷﾞﾌﾄﾎﾟｲﾝﾄ
            div {{ data.current_point.toLocaleString() }}
            div 目標ｷﾞﾌﾄﾎﾟｲﾝﾄ
            div {{ data.target_point.toLocaleString() }}
            div チャレンジ内容
            div {{ data.content }}
          div(class="now-target-content-item")
            img(v-show="data.banner_store_path" :src="$store.state.system.config.storage_addr_list.public + data.banner_store_path")

        div(class="rank-account")
          div(v-for="(item, index) in rank" class="rank-account-item")
            div(v-if="index < 3" class="rank-account-ranking")
              span(style="color: #fff;") {{ index + 1 }}
              img(:src="index == 0 ? '/img/badge_gold.png' : index == 1 ? '/img/badge_silver.png' : index == 2 ? '/img/badge_copper.png' : ''")
            div(v-else class="rank-account-ranking") 
              span {{ index + 1 }}
            div(@click="onAvatarClick(item.profile.id)")
              <pan-thumb :avatar="item.profile.avatar_store_path" :attribute="item.profile.attribute" :current_title="item.viewer_info.current_title" :isStreamer="false" />
            div(class="rank-account-name") {{ item.profile.account_name }}
            div {{ 'P' + item.total_points.toLocaleString() }}

      div(class="content-right")
        .pr-list
          pr-banner(v-if="!ui.profile")
          component-profile-index(v-if="ui.profile" :profile_id="profile_id" v-on:profileclose="profileClose()")

    component-footer-index
</template>

<style src="./Now.scss" lang="scss" scoped>
</style>

<script>
import TargetComponentsHeader from "./components/Header";
import PanThumb from '@/components/PanThumb'

export default {
  name: "SettingTargetNow",
  components: {
    TargetComponentsHeader,
    PanThumb
  },
  data() {
    return {
      ui: {
        profile: false,
      },
      profile_id: -1,
      // percent: undefined,
      // data: {
      //   target_id: undefined,
      //   name: undefined,
      //   target_point: undefined,
      //   content: undefined,
      //   banner_store_path: undefined,

      //   current_point: undefined,
      // },
      // rank: []
    }
  },
  props: {},
  computed: {
    data(){
      return this.$store.state.target.now
    },
    rank() {
      return this.$store.state.target.rankList
    },
    percent() {
      const per = this.data.current_point * 100 / this.data.target_point
      return per > 100 ? 100 : Math.floor(per);
    }
  },
  watch: {},
  mounted() {},
  async created() {
    if (!this.$store.state.target.now.id) {
      await this.$store.dispatch('target/getNowTarget')
    }
    await this.$store.dispatch('target/getTargetViewerRankList',this.$store.state.target.now.id)
    // const per = this.data.current_point * 100 / this.data.target_point;
    // this.percent = per > 100 ? 100 : Math.floor(per);
    // const ret1 = await getTargetListS2();
    // if (ret1.data && ret1.data.data[0]) {
    //   this.data.target_id = ret1.data.data[0].id;
    //   this.data.name = ret1.data.data[0].name;
    //   this.data.target_point = ret1.data.data[0].target_point;
    //   this.data.content = ret1.data.data[0].content;
    //   this.data.banner_store_path = ret1.data.data[0].banner_store_path;

    //   this.data.current_point = ret1.data.data[0].current_point;

    //   const per = this.data.current_point * 100 / this.data.target_point;
    //   this.percent = per > 100 ? 100 : Math.floor(per);

    //   const ret2 = await getTargetRankList(this.data.target_id);
    //   this.rank = ret2.data;
    // }
  },
  updated() {},
  destroyed() {},
  methods: {
    async test() {},
    onAvatarClick(profile_id) {
      this.ui.profile = false;

      this.$nextTick(() => {
        this.profile_id = profile_id;
        this.ui.profile = true;
      })
    },
    profileClose() {
      this.ui.profile = false;
    }
  }
};
</script>
